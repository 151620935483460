<template>
  <div :class="propsClassName">
    <div class="form-check" v-for="(sort, inx) in sortList" :key="inx">
      <input class="form-check-input" type="radio" name="flexRadioDefault1" :id="`flexRadio${inx}`"
      @click="() => changeSort(sort)" :checked="selectedSort===sort">
      <label class="form-check-label" :for="`flexRadio${inx}`"> {{$t(`sabre.search-result.${sort}`)}} </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sort-content-radios',
  props: {
    propsClassName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      filteredItems: 'GET_SABRE_FLIGHT_FILTERED_ITEMS',
      filterConditions: 'GET_SABRE_FILTER_CONDITIONS',
      itinGroups: 'GET_SABRE_FLIGHT_ITIN_GROUPS',
    }),
  },
  watch: {
    selectedSort() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'selectedSort', value: this.selectedSort });
      this.applySort();
    },
    itinGroups() {
      this.applySort();
    },
  },
  data() {
    return {
      sortList: [
        'recommended',
        'cheapest',
        'fastest',
        'departure-time',
        'loading-time',
      ],
      selectedSort: '',
    };
  },
  mounted() {
    this.selectedSort = this.filterConditions?.selectedSort || 'recommended';
  },
  methods: {
    changeSort(code) {
      this.selectedSort = code;
    },
    applySort() {
      const { filteredItems } = this;
      switch (this.selectedSort) {
        case 'recommended': // This must be reconsider more. Current there is no a recommended property in response.
          filteredItems.sort((a, b) => {
            const aItinInfo = this.itinGroups.find((itin) => itin.id === a.itinGroupRef)?.fcSupplied || false;
            const bItinInfo = this.itinGroups.find((itin) => itin.id === b.itinGroupRef)?.fcSupplied || false;
            if (aItinInfo && !bItinInfo) {
              return -1;
            } else if (!aItinInfo && bItinInfo) {
              return 1;
            } else {
              return 0;
            }
          });
          break;
        case 'cheapest':
          filteredItems.sort((a, b) => a.excerpt.totalPrice - b.excerpt.totalPrice);
          break;
        case 'fastest':
          filteredItems.sort((a, b) => a.legs[0].excerpt.elapsedTime - b.legs[0].excerpt.elapsedTime);
          break;
        case 'departure-time':
          filteredItems.sort((a, b) => {
            const aDepartureTime = `${a.legs[0].excerpt.departureDate} ${a.legs[0].excerpt.departure.time}`;
            const bDepartureTime = `${b.legs[0].excerpt.departureDate} ${b.legs[0].excerpt.departure.time}`;
            return new Date(aDepartureTime) - new Date(bDepartureTime);
          });
          break;
        case 'loading-time':
          filteredItems.sort((a, b) => {
            const aArrivalTime = new Date(`${a.legs[0].excerpt.departureDate} ${a.legs[0].excerpt.arrival.time}`);
            const bArrivalTime = new Date(`${b.legs[0].excerpt.departureDate} ${b.legs[0].excerpt.arrival.time}`);
            const aRealArrival = aArrivalTime.setDate(aArrivalTime.getDate() + +a.legs[0].excerpt.arrival.dateAdjustment);
            const bRealArrival = bArrivalTime.setDate(bArrivalTime.getDate() + +b.legs[0].excerpt.arrival.dateAdjustment);
            return aRealArrival - bRealArrival;
          });
          break;
        default:
      }
      this.$store.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', filteredItems);
    },
  },
};
</script>
